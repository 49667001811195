import React from "react" 
import { Helmet } from "react-helmet";
import BrandLogo from "../../../images/logo.png";
import { useLocation } from "@reach/router";
const OfficeSchema = ({ officeData }) => {
   const { title, description, ratingValue, reviewCount } = officeData;
   const location = useLocation();
   const schemaJSON = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Property Estate Agents in Chelmsford",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "CM1 1EH",
      "streetAddress": "2 Tindal Square",
      "addressRegion": "Chelmsford",
      "addressLocality": "Essex"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-chelmsford-branch-58575f2ac3.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": 399,
      "bestRating": "5"
    },
    "telephone": "01245 492424",
    "url": "https://www.balgoresproperty.co.uk/branches/estate-agents-in-chelmsford/",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://www.google.com/maps?sca_esv=c744cb070de47b7e&sxsrf=ADLYWIIhskPAlzpLR6u2LLG4WOlNa9zJlA:1732530812776&uact=5&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2JhbGdvcmVzIGNoZWxtc2ZvcmQyChAjGIAEGCcYigUyEBAuGIAEGBQYhwIYxwEYrwEyChAAGIAEGBQYhwIyBRAAGIAEMgUQABiABDIFEAAYgAQyBhAAGBYYHjILEAAYgAQYhgMYigUyCBAAGIAEGKIEMggQABiABBiiBDIfEC4YgAQYFBiHAhjHARivARiXBRjcBBjeBBjgBNgBAUjpGFDpDVihGHAGeAGQAQGYAboBoAGbEaoBBDIuMTW4AQPIAQD4AQGYAhagAsYQwgIKEAAYsAMY1gQYR8ICDRAAGLADGNYEGEcYyQPCAg4QABiABBiwAxiSAxiKBcICBBAjGCfCAhMQLhiABBhDGMcBGIoFGI4FGK8BwgIQEC4YgAQYQxjHARiKBRivAcICChAAGIAEGEMYigXCAgsQABiABBiRAhiKBcICCxAuGIAEGMcBGK8BwgIWEC4YgAQYQxjHARiYBRiZBRiKBRivAcICDhAuGIAEGMcBGI4FGK8BwgINEAAYgAQYQxjJAxiKBcICERAuGIAEGJECGMcBGIoFGK8BmAMAiAYBkAYKugYGCAEQARgUkgcENi4xNqAHw6oC&um=1&ie=UTF-8&fb=1&gl=uk&sa=X&geocode=KY-5zITX69hHMU4-gSDxKtqg&daddr=2+Tindal+Square,+Chelmsford+CM1+1EH",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00"
    ]
  };
  const schema = JSON.stringify(schemaJSON);
   return (
        <> 
          {location && location.pathname == "/branches/estate-agents-in-chelmsford/" ?
            <Helmet>
              <script type="application/ld+json">{schema}</script>
            </Helmet>
            :
            <Helmet>
                <script type="application/ld+json">
                  {`{
                    "@context": "http://schema.org",
                    "@type": "LocalBusiness",
                    "name": "${title}",
                    "description": "${description}",
                    "brand": {
                      "@type": "Brand",
                      "name": "Balgores Property Group",
                      "image": "${BrandLogo}"
                    },
                    "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": "${ratingValue}",
                      "reviewCount": "${reviewCount}",
                      "bestRating": "5"
                    }
                  }`}
                </script>
            </Helmet>
          }
        </>
  );
}
export default OfficeSchema